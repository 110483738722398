import Index from "../app/sidebar/";
import TopBar from "../topbar";
import SimpleBar from "simplebar-react";
import { useRef, useState } from "react";

const DefaultLayout = ({ children }) => {
  const ref = useRef(null);
  const [scrollTop, setScrollTop] = useState(0);

  const handleScroll = () => {
    const contentTop = ref.current
      .getContentElement()
      .getBoundingClientRect().top;
    const wrapperTop = ref.current.el.getBoundingClientRect().top;
    setScrollTop(wrapperTop - contentTop);
  };

  return (
    <>
      <div className={"flex flex-row"}>
        <Index />
        <div className={`flex w-10/12 relative overflow-hidden`}>
          <TopBar scrollTop={scrollTop} />
          <SimpleBar
            ref={ref}
            scrollableNodeProps={{
              onScroll() {
                handleScroll();
              },
            }}
            className={`max-h-screen w-full pt-24`}
          >
            {children}
          </SimpleBar>
        </div>
      </div>
    </>
  );
};

export default DefaultLayout;
