export const routes = [
    {
        title: 'General',
        separator: true
    },
    {
        title: 'Dashboard',
        icon: 'fa-grid-horizontal',
        href: '/'
    },
    {
        title: 'Products',
        icon: 'fa-phone-office',
        href: '/products',
        children: [
            {
                title: 'My Products',
                href: '/account/products/'
            },
            {
                title: 'View Products',
                href: '/products/'
            },
        ]
    },
    {
        title: 'Services',
        icon: 'fa-bars-staggered',
        href: '/services',
        children: [
            {
                title: 'Active Services',
                href: '/account/services'
            },
            {
                title: 'View Services',
                href: '/services/'
            },
        ]
    },
    {
        title: 'Orders',
        icon: 'fa-bags-shopping',
        href: '/orders'
    },
    {
        title: 'Support',
        icon: 'fa-life-ring',
        href: 'https://support.skypbx.com/',
        new: true
    },
    {
        title: 'Account Management',
        separator: true
    },
    {
        title: 'Invoices',
        icon: 'fa-receipt',
        href: '/invoices'
    },
    {
        title: 'Payments',
        icon: 'fa-wallet',
        href: '/payments',
        children: [
            {
                title: 'View Payments',
                href: '/payments'
            },
            {
                title: 'Payment Methods',
                href: '/account/payment-methods'
            },
        ]
    },
    {
        title: 'User Management',
        separator: true
    },
    {
        title: 'Users',
        icon: 'fa-users',
        href: '/users'
    },
    {
        title: 'Permissions',
        icon: 'fa-user-lock',
        href: '/users/permissions'
    },
]
