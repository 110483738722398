import Image from "next/image";
import { useAuth } from "../../../lib/auth";
import { routes } from "../../../config/routes";
import MenuItem from "./menuItem";
import SimpleBar from "simplebar-react";
import Cart from "./cart";
import AccountSwitcher from "./accountSwitcher";
import useSiteTheme from "../../../hooks/useSiteTheme";
import Logo from "../logo";

const Sidebar = () => {
  // const { user } = useAuth();
  const { toggleTheme } = useSiteTheme();
  return (
    <div
      className={
        "h-screen w-2/12 dark:text-gray-50 dark:bg-gray-800 border-r border-r-gray-200/50 dark:border-r-gray-700 bg-white shadow-xl"
      }
    >
      <SimpleBar className={"h-screen py-7"}>
        <div className={"w-40 mb-9 mx-7"}>
          <Logo />
        </div>
        <AccountSwitcher />
        <Cart />
        <ul className={"mt-5"}>
          {routes.map((route, index) => (
            <MenuItem key={index} route={route} />
          ))}
        </ul>
      </SimpleBar>
    </div>
  );
};

export default Sidebar;
