import Link from "next/link";
import { useCart } from "../../../lib/cart";
import { Transition } from "@headlessui/react";

const Cart = () => {
  let dollarUS = Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });
  const { cartItems, cartTotal, getCartQuantity } = useCart();

  return (
    <Transition
      enter="transition duration-100 ease-out"
      enterFrom="transform scale-95 opacity-0"
      enterTo="transform scale-100 opacity-100"
      leave="transition duration-75 ease-out"
      leaveFrom="transform scale-100 opacity-100"
      leaveTo="transform scale-95 opacity-0"
      show={cartItems.length > 0}
    >
      <div
        className={
          "rounded p-6 flex flex-col dark:bg-gray-900 dark:bg-opacity-30 bg-gray-200 mx-7"
        }
      >
        <div
          className={"flex flex-row items-center text-gray-400 mb-3 text-sm"}
        >
          <i className={"fad fa-shopping-basket mr-3 text-lg"} />
          Shopping Cart
        </div>
        <div className={"flex flex-row items-center justify-between"}>
          <div>
            <h2 className={"text-2xl"}>{getCartQuantity()}</h2>
            <p className={"text-sm text-gray-500"}>Items</p>
          </div>
          <div className={"text-right"}>
            <h2 className={"text-2xl"}>{dollarUS.format(cartTotal)}</h2>
            <Link href={"/checkout"} passHref>
              <a className={"text-sm text-primary hover:underline"}>Checkout</a>
            </Link>
          </div>
        </div>
      </div>
    </Transition>
  );
};

export default Cart;
