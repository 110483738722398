const WelcomeGreeting = ({user}) => {
    const currentDate = new Date();
    const hour = currentDate.getHours();
    let greeting = '';
    if(hour >= 0 && hour <= 12){
        greeting = 'Good Morning';
    } else if(hour >= 12 && hour <= 18){
        greeting = 'Good Afternoon';
    } else if(hour >= 19 && hour <= 23){
        greeting = 'Good Evening';
    }
    return (
        <>
            {`${greeting}, ${user && user.displayName.split(" ", 2)[0]}`}
        </>
    )
}

export default WelcomeGreeting
