import { useLocalStorage } from "usehooks-ts";
import { useEffect } from "react";

const useSiteTheme = () => {
  const [isDarkTheme, setDarkTheme] = useLocalStorage("darkTheme", true);

  const toggleTheme = () => {
    setDarkTheme((prevValue) => !prevValue);
  };

  useEffect(() => {
    if (isDarkTheme) {
      document.documentElement.classList.add("dark");
    } else {
      document.documentElement.classList.remove("dark");
    }
  }, [isDarkTheme]);

  return {
    isDarkTheme,
    toggleTheme,
  };
};

export default useSiteTheme;
