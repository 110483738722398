import { useAuth } from "../../../lib/auth";
import { Menu, Transition } from "@headlessui/react";
import { Fragment } from "react";

const AccountSwitcher = () => {
  const { user, accountData } = useAuth();
  return (
    <Menu as="div" className="relative mx-6">
      <div>
        <Menu.Button className="flex flex-row items-center rounded shadow-sm p-4 px-5 w-full text-sm font-medium dark:text-white bg-secondary bg-opacity-5 hover:bg-opacity-10 z-10">
          <i className={"fad fa-random mr-3"} />
          <span>Account Switcher</span>
          {accountData && accountData.permissions ? (
            <i className={"fas fa-caret-down ml-auto"} />
          ) : (
            <i className={"fad fa-spinner-third ml-auto fa-spin"} />
          )}
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="origin-top-right absolute overflow-hidden right-0 mt-2 w-full rounded shadow-lg bg-gray-900 z-20">
          <div>
            {accountData &&
              accountData.permissions &&
              accountData.permissions.map((permission) => (
                <Menu.Item key={permission.account.id}>
                  <button
                    onClick={() => alert("switch account")}
                    className={
                      "flex flex-col text-left w-full text-gray-300 p-4 text-xs hover:bg-secondary hover:bg-opacity-20"
                    }
                  >
                    <span>{permission.account.companyName}</span>
                    <span className={"mt-1 text-[10px] text-gray-500"}>
                      ID: {permission.account.id}
                    </span>
                  </button>
                </Menu.Item>
              ))}
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
};

export default AccountSwitcher;
