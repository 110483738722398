import UserDropdown from "./userDropdown";
import useSiteTheme from "../../hooks/useSiteTheme";

const TopBar = ({ scrollTop }) => {
  const { toggleTheme } = useSiteTheme();
  return (
    <div
      className={`h-24 flex flex-row items-center justify-end w-10/12 fixed top-0 z-40 transition-all ${
        scrollTop > 50 ? "bg-gray-900 bg-opacity-50" : ""
      }`}
    >
      <span
        className={
          "mr-3 p-2 px-3 rounded hover:bg-zinc-100/50 dark:hover:bg-zinc-900 text-primary select-none cursor-pointer"
        }
        onClick={() => toggleTheme()}
      >
        Change Theme
      </span>
      <UserDropdown />
    </div>
  );
};

export default TopBar;
