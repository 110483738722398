import {useAuth} from "../../lib/auth";
import {useState} from "react";
import Link from "next/link";
import WelcomeGreeting from "../greeting";

const UserDropdown = () => {
    const [showDropdown, setShowDropdown] = useState(false);
    const {user, signOut} = useAuth();
    return (
        <div className={'mr-9 relative'}>
            <div className={'flex items-center justify-center w-10 h-10 rounded-full bg-gray-700 bg-contain bg-no-repeat bg-center cursor-pointer z-40'} style={{backgroundImage: `url('/avatar.jpg')`}} onClick={() => setShowDropdown(!showDropdown)} />
            {showDropdown ? (
                <>
                    <div className="fixed inset-0 h-full w-full z-40" onClick={() => setShowDropdown(false)} />
                    <div className={'absolute rounded overflow-hidden right-0 top-14 bg-gray-700 shadow-lg w-64 z-50'}>
                        <div className={'px-5 py-4 text-white'}>
                            <WelcomeGreeting user={user} />
                        </div>
                        <div className={'border-b border-b-gray-600'} />
                        <ul>
                            <li>
                                <Link href={'/account'} passHref>
                                    <a className={'px-5 py-4 text-sm flex flex-row items-center text-gray-400 hover:bg-gray-800'}>
                                        <i className={'fas fa-cog mr-3'} /> Account Settings
                                    </a>
                                </Link>
                            </li>
                            <li>
                                <button onClick={() => signOut()} className={'px-5 py-4 text-sm w-full flex flex-row items-center text-gray-400 hover:bg-gray-800'}>
                                    <i className={'fas fa-sign-out mr-3'} /> Logout
                                </button>
                            </li>
                        </ul>
                    </div>
                </>
            ) : null }
        </div>
    )
}

export default UserDropdown
