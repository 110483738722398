import Link from "next/link";
import { useRouter } from "next/router";
import { Disclosure, Transition } from "@headlessui/react";

const MenuItem = ({ route }) => {
  const router = useRouter();
  if (route.separator) {
    return (
      <li>
        <span className={"px-7 py-3 flex text-xs uppercase"}>
          {route.title}
        </span>
      </li>
    );
  } else if (!route.children) {
    return (
      <li>
        <Link href={route.href} passHref>
          <a
            target={route.new ? "_new" : null}
            className={`px-7 py-3 flex text-sm items-center hover:bg-secondary hover:bg-opacity-10 ${
              router.pathname === route.href
                ? "dark:text-white bg-secondary bg-opacity-5"
                : "text-gray-500"
            }`}
          >
            <i className={`fa-duotone ${route.icon} mr-3`} /> {route.title}
          </a>
        </Link>
      </li>
    );
  } else {
    return (
      <li>
        <Disclosure>
          {({ open }) => (
            <>
              <Disclosure.Button
                className={`px-7 py-3 w-full flex items-center text-sm text-gray-500 hover:cursor-pointer hover:bg-secondary hover:bg-opacity-10`}
              >
                <i className={`fa-duotone ${route.icon} mr-3`} /> {route.title}
                <i
                  className={`fa-solid fa-caret-down ml-auto transition-all ${
                    open ? "rotate-180" : "rotate-0"
                  }`}
                />
              </Disclosure.Button>

              <Transition
                enter="transition duration-100 ease-out"
                enterFrom="transform scale-95 opacity-0"
                enterTo="transform scale-100 opacity-100"
                leave="transition duration-75 ease-out"
                leaveFrom="transform scale-100 opacity-100"
                leaveTo="transform scale-95 opacity-0"
              >
                <Disclosure.Panel
                  as={"ul"}
                  className={"bg-secondary bg-opacity-5"}
                >
                  {route.children.map((child, index) => (
                    <li key={index}>
                      <Link href={child.href} passHref>
                        <a
                          className={`px-7 py-3 text-sm flex items-center text-gray-500 hover:bg-secondary hover:bg-opacity-5 ${
                            router.pathname === child.href
                              ? "dark:text-white bg-secondary bg-opacity-5"
                              : ""
                          }`}
                        >
                          {child.title}
                        </a>
                      </Link>
                    </li>
                  ))}
                </Disclosure.Panel>
              </Transition>
            </>
          )}
        </Disclosure>
      </li>
    );
  }
};
export default MenuItem;
